body {
  background-color: #98ded9;
  font-family: "ambroise-std";
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  margin-top: 50px;
  padding: 20px;
  justify-content: center;
}

.main___profile {
  background-color: #f2fcfc;
  padding: 50px;
  margin-right: 20px;
  text-align: center;
  font-size: 1.2em;
  border-radius: 2px;
  align-self: flex-start;
}

.main__container {
  background-color: #f2fcfc;
  padding: 50px;
  font-size: 1.2em;
  border-radius: 2px;
}

.main___profile_image {
  height: 180px;
  width: 180px;
  border: 4px solid rgb(154, 155, 155);
  border-radius: 50%;
}

.description {
  margin-bottom: 50px;
}

h2 {
  margin: 2px 0px;
}

.project__list ul {
  font-size: 1.1em;
  line-height: 1.4;
  list-style-type: none;
  padding: 0;
}

.project__list_link {
  text-decoration: none;
}

.project__list__item {
  color: rgb(0, 0, 0, 0.8);
  transition: all 1s;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 4px;
}

.project__list__item:hover {
  color: rgb(0, 0, 0, 1);
  background-color: rgb(199, 193, 193);
}

.profile__visual_images {
  margin-top: 20px;
}

.profile__visual_image_github {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.profile__visual_image_linkedin {
  height: 20px;
  width: 20px;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.profile__name__cv {
  display: flex;
  justify-content: center;
}

.profile__name {
  margin-right: 10px;
}

.profile__cv {
  align-self: center;
}

.profile__cv__link__image {
  width: 30px;
  height: 30px;
}
